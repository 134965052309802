<template>
    <div>
        <div class="level buttons">
            <button class="button" :class="{'is-success': ready_for_interview == 1}" @click="updateFilters('ready_for_interview')">Ready for Interview</button>
        </div>
        
        <div class="notification is-warning" v-if="loading_summary == true">
            Loading applications ...
        </div>
        
        <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="loading_summary == false">
            <thead>
                <tr>
                    <th>Application Date</th>
                    <th>Name</th>
                    <th>Grade</th>
                    <th>Personal</th>
                    <th>Health</th>
                    <th>NISCC</th>
                    <th>NMC</th>
                    <th>Interview</th>
                    <th>Last Applicant Action</th>
                    <th>Last Admin Action</th>
                    <th >&nbsp;</th>
                    <th >&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_applications" :key="row.member_application_id">
                    <td>{{ row.date }}</td>
                    <td>{{ row.forename }} {{ row.surname }}</td>
                     <td >
                        <div class="tags"><span v-for="grade in row.grades" :key="grade.name" class="tag">{{ grade.grade_code}}</span></div>
                    </td>
                    <!-- Personal Details -->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(1, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(1, row.stages)">Completed</span>
                        <span class="tag is-success" v-if="isStageApproved(1, row.stages)">Approved</span>
                    </td>
                    <!-- Health-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(2, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(2, row.stages)">Completed</span>
                        <span class="tag is-success" v-if="isStageApproved(2, row.stages)">Approved</span>
                    </td>
                    <!-- NISCC-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(10, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(10, row.stages)">Completed</span>
                        <span class="tag is-success" v-if="isStageApproved(10, row.stages)">Approved</span>
                    </td>
                    <!-- NMC-->
                    <td>
                        <span class="tag is-light" v-if="isStageActive(11, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(11, row.stages)">Completed</span>
                        <span class="tag is-success" v-if="isStageApproved(11, row.stages)">Approved</span>
                    </td>
                     <!-- Interview-->
                     <td>
                        <span class="tag is-light" v-if="isStageActive(5, row.stages)">Active</span>
                        <span class="tag is-warning" v-if="isStageCompleted(5, row.stages)">Completed</span>
                        <span class="tag is-success" v-if="isStageApproved(5, row.stages)">Approved</span>
                    </td>
                    <td>{{ row.last_applicant_action }}</td>
                    <td>{{ row.last_admin_action }}</td>
                    <td>
                        <span v-if="row.cleared==0">{{ row.status_name }}</span>
                        <span v-if="row.cleared==1" class="tag is-danger">CLEARED</span>
                    </td>
                    <td style="display:none"><router-link :to="'/applications/' + row.member_application_id"><span class="tag is-small is-success">View</span></router-link></td>
                    <td><router-link :to="'/applications/members/' + row.member_id" target="_blank"><span class="tag is-small is-info">View</span></router-link></td>
                </tr>
            </tbody>

        </table>
        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import ApplicationsList from '@/components/Applications/ApplicationsList.vue';

export default {
    
      name: 'ApplicationsDashboard',
        mixins: [MemberMixin],
        components: {
            //ApplicationsList
        },
        data: function () {
            return {
                   search: '',
                   applications: [],
                   loading_summary: true,
                   loading_search: false,
                   search_applications: [],
                   search_performed: false,
                   ready_for_interview: 0
            }
        },
        methods: {
            updateFilters(filter_name)
            {
                if(this[filter_name] == 1)
                {
                    this[filter_name] = 0
                }
                else {
                    this[filter_name] = 1
                }
            },  
            isStageActive(stage_id, stages) {
                // Loop through each stage in the stages object
                for (let key in stages) {
                    if (Object.prototype.hasOwnProperty.call(stages, key)) {
                        const stage = stages[key];
                        
                        // Check if the current stage_id matches and is approved
                        if (stage.stage_id === stage_id && stage.active === 1 && stage.started === 0 && stage.approved === 0) {
                            return true; // Return true if the stage is approved
                        }
                    }
                }

                return false; // Return false if no match or if not approved
            },
            isStageCompleted(stage_id, stages) {
                // Loop through each stage in the stages object
                for (let key in stages) {
                    if (Object.prototype.hasOwnProperty.call(stages, key)) {
                        const stage = stages[key];
                        
                        // Check if the current stage_id matches and is approved
                        if (stage.stage_id === stage_id && stage.started === 1 && stage.approved === 0) {
                            return true; // Return true if the stage is approved
                        }
                    }
                }

                return false; // Return false if no match or if not approved
            },
            isStageApproved(stage_id, stages) {
                // Loop through each stage in the stages object
                for (let key in stages) {
                    if (Object.prototype.hasOwnProperty.call(stages, key)) {
                        const stage = stages[key];
                        
                        // Check if the current stage_id matches and is approved
                        if (stage.stage_id === stage_id && stage.approved === 1) {
                            return true; // Return true if the stage is approved
                        }
                    }
                }

                return false; // Return false if no match or if not approved
            },
            async getApplications() {
                this.applications = [];

                this.loading_summary=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications?ready_for_interview=" + this.ready_for_interview)
                .then(
                    (applications => {
                        this.$set(this, "applications", applications);
                        this.loading_summary=false
                    }).bind(this)
                );
            },
            searchApplications() {
                this.search_performed = true

                this.loading_search=true
                UtilityService.getSimpleApiData(this.accessToken, "member_applications?search=" + this.search)
                .then(
                    (search_applications => {
                        this.$set(this, "search_applications", search_applications);
                        this.loading_search=false
                    }).bind(this)
                );
            }
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getApplications();
            });
        
        },
        computed : {
            /*open_applications: function () {
                let open_applications =  this.applications.filter(function(u) {
                    if(  u.status == 0) 
                    {
                        return 1
                    }
                })
                return open_applications
            },
            new_applications: function () {
                let new_applications =  this.applications.filter(function(u) {
                    if(  u.status == 1) 
                    {
                        return 1
                    }
                })
                return new_applications
            },
            in_progress_applications: function () {
                let in_progress_applications =  this.applications.filter(function(u) {
                    if(  u.status > 1) 
                    {
                    return 1
                    }
                })
                return in_progress_applications
            },
            pending_applications: function () {
                let pending_applications =  this.applications.filter(function(u) {
                    if(  u.status == 3) 
                    {
                    return 1
                    }
                })
                return pending_applications
            }, */
            filtered_applications: function () {
                let filtered_applications =  this.applications.filter(function(u) {
                    if(  u.display == 1) 
                    {
                    return 1
                    }
                })
                return filtered_applications
            }
        },
        watch: {
            
            ready_for_interview() {
                this.getApplications();
                
            }
        },
    }
</script>